import { z } from "zod";

const TeamSchema = z.object({
  pickId: z.number(),
  logo: z.string(),
  name: z.string(),
  setByBlast: z.boolean().optional(),
});

const PredictionSchema = z.object({
  groupId: z.number(),
  index: z.number(),
  team: TeamSchema,
});
export type Prediction = z.infer<typeof PredictionSchema>;

const GroupSchema = z.object({
  sectionId: z.number(),
  groupId: z.number(),
  picksAllowed: z.boolean(),
  teams: z.array(TeamSchema),
  stageName: z.string(),
  picks: z.array(
    z.object({
      index: z.number(),
      teams: z.array(TeamSchema),
    }),
  ),
});
export type Group = z.infer<typeof GroupSchema>;

export const TournamentLayoutSchema = z.object({
  eventId: z.number(),
  stage: z.string(),
  groups: z.array(GroupSchema),
});
export type TournamentLayout = z.infer<typeof TournamentLayoutSchema>;

export const PostPredictionsBodySchema = z.object({
  sectionId: z.number(),
  groupId: z.number(),
  index: z.number(),
  picks: z.array(z.number()),
});
export type PostPredictionsBody = z.infer<typeof PostPredictionsBodySchema>;

export const TournamentPredictionSchema = z.object({
  hasMajorPass: z.boolean(),
  predictions: z.array(PredictionSchema),
});
export type TournamentPrediction = z.infer<typeof TournamentPredictionSchema>;
