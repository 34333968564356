import { z } from "zod";
import { blastApi } from "../../blastApi";
export const GetSteamConnectionLinkResponseSchema = z.object({
  url: z.string(),
});

export const getSteamConnectionLink = async ({ redirectPath }: { redirectPath: string }) => {
  const { data } = await blastApi.get(`/v1/users/connections/steam/link`, {
    params: {
      redirectPath,
    },
  });
  return GetSteamConnectionLinkResponseSchema.parse(data);
};
